export const allFAQs = [
    {
        title: 'Who can use NamasPay?',
        desc: `NamasPay is open to travellers from around the world. Currently, NamasPay can be used by citizens from the following countries:<br/><br/>
                Argentina, Australia, Brazil, Canada, China, France, Germany, India, Indonesia, Italy, Japan, Mexico, Republic of Korea, Russia, Saudi Arabia, South Africa, Türkiye, United Kingdom (UK), United States of America (USA), European Union (EU) and African Union (AU).`
    },
    {
        title: 'Is my country in European Union?',
        desc: `NamasPay can be used by citizens of following European Union countries:<br/><br/>
                Austria, Belgium, Bulgaria, Croatia, Republic of Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain and Sweden.`
    },
    {
        title: 'Is my country covered in African Union?',
        desc: `NamasPay can be used by citizens of following African Union countries:<br/><br/>
                Algeria, Angola, Benin, Botswana, Burkina Faso, Burundi, Cameroon, Cape Verde, Central Africa, Chad, Comoros, Congo, Democratic Republic of the Congo, Djibouti, Egypt, Equatorial Guinea, Eritrea, Eswatini, Ethiopia, Gabon, Gambia, Ghana, Guinea, Guinea-Bissau, Ivory Coast, Kenya, Lesotho, Liberia, Libya, Madagascar, Malawi, Mali, Mauritania, Mauritius, Morocco, Mozambique, Namibia, Niger, Nigeria, Rwanda, Sao Tome and Principe, Senegal, Seychelles, Sierra Leone, Somalia, South Africa, South Sudan, Sudan, Tanzania, Togo, Tunisia, Uganda, Western Sahara, Zambia, Zimbabwe.`
    },
    {
        title: 'Is NamasPay secure?',
        desc: 'NamasPay focuses on the safety and security of your money by strictly adhering to regulatory guidelines by RBI &  NPCI. With NamasPay’s digital payments, not only do you get convenience, but you also avoid the risk of losing your cash or cards. Every transaction you make is device-lock protected. The app also constantly checks for fraudulent activity, thereby ensuring your money is safe within the app and while making payments.'
    },
    {
        title: 'What documents are required to set up my NamasPay wallet?',
        desc: 'To set up your NamasPay wallet, you will be made to complete a physical verification which will require your passport and Visa.'
    },
    {
        title: 'Where can I spend with NamasPay?',
        desc: 'Cabs, restaurants, online shopping, hotels, malls - you name it, NamasPay will help you pay it! Pay any merchant UPI ID with NamasPay while you travel across the country.'
    },
    {
        title: 'What are NamasPay\'s charges?',
        desc: `Here are all the charges that come with NamasPay - nothing more, nothing less.<br/><br/>
                - One time set-up fee of INR ~1650<br/><br/>
                - ~4% of your amount every time you add money<br/><br/>
                - ~1% of your amount when you withdraw money`
    },
    {
        title: 'How do I add money into my NamasPay wallet?',
        desc: 'You can add money into your NamasPay wallet from any Mastercard or Visa card! Once entered in your currency, the money shall get added and stored as Rupees in your wallet, which you can then spend anywhere in India.'
    },
    {
        title: 'Can I deposit cash to add money into my NamasPay wallet?',
        desc: 'We currently do not accept cash deposits to add money into your wallet. However, you can use any Mastercard or Visa card to transfer money into your wallet instead!'
    },
    {
        title: 'How do you Scan and Pay?',
        desc: `With Scan and Pay, you can make a payment by simply scanning a merchant\'s QR code and entering the amount you would like to send!<br/><br/>
                Here\'s how you can Scan and Pay:<br/><br/>
                - Open your NamasPay app and click on \'Scan & Pay\'<br/><br/>
                - A scanner opens up, with which you can scan any merchant\'s QR code<br/><br/>
                - Once scanned, enter the amount you wish to send to the merchant and click \'Pay\'<br/><br/>
                - That\'s it! You just made a payment using Scan and Pay.`
    },
    {
        title: 'How do you pay using your UPI ID?',
        desc: `On NamasPay, you can make payments online using your UPI ID! Here\'s how it works:<br/><br/>
                - Once you reach the payments screen of the app or website you are shopping on, click on the \'Add new UPI ID\' option while choosing your mode of payment<br/><br/>
                - Enter your UPI ID, which can be copied from the home screen of your NamasPay app<br/><br/>
                - Once entered, you can proceed to pay<br/><br/>
                - You will then receive a request for the payment on your NamasPay app<br/><br/>
                - Accept the payment request by clicking on \'Pay\', and you\'re done!`
    }
];
