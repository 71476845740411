// Modules
import React, {useEffect, useState} from 'react';

// Styles
import classes from './HomeUI.module.scss';

// Components
import CTA from '../elements/CTA';

// Utils & Constants
import {onDownloadClick} from "../utils/helperFunctions";
import {META_INFO} from "../constants/metaConstants";


const SupportSection = () => {
    const [isBrowser, setIsBrowser] = useState(false);
    useEffect(() => {
        if (process.browser)
            setIsBrowser(true);
    }, [])

    const isDesktop = () => {
        if (!isBrowser)
            return true;

        const isMobile = ('ontouchstart' in window); // likely mobile
        return !isMobile;
    }

    return (
        <div className={classes.supportContainer}>
            <div className="sectionHeading textAlign--center--mobile flex--1">We’re here<br />
                to help, 24x7
            </div>
            <div className={`${classes.details} flex--1`}>
                <div className={classes.desc}>Got questions? Our support team is available via chat or call anytime you need. Reach out to us at <a href={`mailto:${META_INFO.EMAIL}`} rel="noreferrer" style={{color: 'blue'}}>{META_INFO.EMAIL}</a></div>
                {/*<CTA className='marginTop--l3' type="primary" text={isDesktop ? "Join Now" : "Download App"} onAction={onDownloadClick}></CTA>*/}
            </div>
        </div>
    );
};

export default SupportSection;
