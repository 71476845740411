// Modules
import React from 'react';
import Link from "next/link";

// Styles
import classes from './HomeUI.module.scss';

// Constants
import {META_INFO} from "../constants/metaConstants";


const Footer = () => {
    const items = [
        {label: 'FAQ', route: '/faq'},
        {label: 'Contact Us', route: '/contact-us'},
        {label: 'Privacy Policy', route: '/privacy-policy'},
        {label: 'Terms of Service', route: '/terms-of-service'}
    ];

    return (
        <div className={classes.footerWrapper}>
            <div className='pagePad__x'>
                <div className={classes.footer}>
                    <div className="flex--1" style={{ maxWidth: 360 }}>
                        <Link href={'/'}>
                            <img className='cursor--pointer' src="/assets/icons/logoFull.svg?v=2" alt="NamasPay logo" style={{ height: 32 }} />
                        </Link>
                        <h2 className="fontSize--h4 color--secondary marginTop--l3" style={{lineHeight: 1.5}}>{META_INFO.LONG_DESC}</h2>
                    </div>

                    <div className="flex--1 padLeft--l2" style={{ maxWidth: 420, color: 'rgba(246, 246, 246, 1)' }}>
                        {items?.map((item, index) => (
                            <Link key={index} href={item?.route} target={'_blank'}>
                                <a className={`cursor--pointer display--block color--link ${index>0 ? 'marginTop--l3' : ''} ${classes.footerLink}`}>{item?.label}</a>
                            </Link>
                        ))}
                    </div>
                </div>

                <div style={{height: 1, background: 'rgba(255,255,255,0.1)', marginTop: 80, marginBottom: 80}}></div>

                <div className="text__align--center color--secondary fontSize--h5 marginTop--l10">&copy; Copyright 2023 Triotech Ltd</div>
                <div className="text__align--center color--secondary fontSize--h5 marginTop--l1">All rights reserved</div>
            </div>
        </div>
    );
};

export default Footer;
