// Modules
import React from 'react';

// Styles
import classes from './CTA.module.scss';

const CTA = ({ className, type, leftImg, text, rightImg, onAction, disabled, style }) => {
    return (
        <div
            className={`${className || ''} ${classes.customCta} ${classes[type] || ''} ${disabled ? classes.disabled : ''}`}
            style={style ? { ...style } : {}}
            onClick={() => {
                if (onAction)
                    onAction();
            }}>
            {leftImg}
            {text}
            {rightImg}
        </div>
    );
};

export default CTA;
