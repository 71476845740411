// Modules
import React, {useEffect, useState} from 'react';

// Styles
import classes from './HomeUI.module.scss';

// Components
import CTA from '../elements/CTA';
import Header from "./Header";
import Footer from "./Footer";
import SupportSection from "./SupportSection";

// Constants & Utils
import {isAndroid, isIOS, onDownloadClick} from "../utils/helperFunctions";
import {allFAQs} from "../constants/faqConstants";
import {acrossCountryList, gettingStarted, quickFeatures, upiFeatures, upiUsages} from "../constants/commonConstants";


const HomeUI = () => {
    const [openedFaqIndex, setOpenedFaqIndex] = useState(-1);
    const [isBrowser, setIsBrowser] = useState(false);
    const [welcomeToTextIndex, setWelcomeToTextIndex] = useState(0);
    const welcomeToTextList = ['convenient payments', 'NamasPay', 'India'];
    const arrayOf100Length = Array.from(Array(100).keys());
    const arrayOf1000Length = Array.from(Array(1000).keys());


    useEffect(() => {
        if (process.browser) {
            setIsBrowser(true);
        }

        setInterval(() => {
            setWelcomeToTextIndex(prevState => {
                if (prevState === (welcomeToTextList?.length-1))
                    return 0;

                return prevState+1;
            })
        }, 2000)
    }, [])

    const isMobile = () => {
        if (!isBrowser)
            return true;

        const isMobile = ('ontouchstart' in window); // likely mobile
        return isMobile;
    }

    const isDesktop = () => {
        if (!isBrowser)
            return true;

        const isMobile = ('ontouchstart' in window); // likely mobile
        return !isMobile;
    }


    return (
        <main className="main">
            <Header/>

            <div className={`${classes.sec1} pagePad__x`}>
                <div className={classes.details}>
                    <h1 className={classes.pageTitle}>Cashless UPI payments for foreign travellers in India</h1>
                    <h2 className={`${classes.desc} marginTop--l3`}>Scan & pay with UPI using your own digital wallet
                    </h2>
                    <div className={`${classes.storeImagesWrapper} display--flex flex__justifyContent--start flex__flow--wrap`}>
                        {(isAndroid() || !isIOS() || !isBrowser) && <a href={'https://play.google.com/store/apps/details?id=in.namaspay.app'} target={'_blank'}
                            rel="noreferrer" className={classes.storeImg}>
                            <img src="/assets/images/play_store.png" className={classes.storeImg}/>
                        </a>}
                        {(isIOS() || !isAndroid() || !isBrowser) && <a href={'https://apps.apple.com/in/app/namaspay/id6449447184'} target={'_blank'}
                            rel="noreferrer" className={classes.storeImg}>
                            <img src="/assets/images/app_store.png" className={classes.storeImg}/>
                        </a>}
                    </div>
                </div>

                <img src="/assets/images/hero.webp" className={classes.img} />
            </div>

            <div className={classes.sec2Wrapper}>
                <div className={`${classes.sec2} pagePad__x`}>
                    <div className={classes.desc}>Accepting currencies from around the globe</div>

                    <div className={`${classes.flagsAnimationContainer} marginTop--l3`}>
                        <div className={classes.flagsAnimation}>
                            {arrayOf1000Length?.map((item, index) => (
                                <img key={index} src="/assets/icons/flags.png" />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.sec3Wrapper}>
                <div className={`${classes.sec3} pagePad__x`}>
                    {
                        isMobile()
                        ?
                        <>
                            <div className='sectionHeading text__align--center marginBottom--l4'>Welcome to<br/>{welcomeToTextList?.[welcomeToTextIndex]}</div>
                            <img className="width--full" src="/assets/images/hero_mobile.png?v=2" />
                        </>
                        :
                        <div className='position--relative'>
                            <img className="width--full" src="/assets/images/hero_desktop.png?v=3"/>
                            <div className={`${classes.welcomeToText} sectionHeading`}>Welcome to<br/>{welcomeToTextList?.[welcomeToTextIndex]}</div>
                        </div>
                    }

                    <div className={`${classes.desc} marginTop--l5`}>With NamasPay, you can:</div>
                    <div className={`${classes.usageWidgetRow} marginTop--l4`}>
                        {upiUsages?.map((item, index) => (
                            <div key={index} className={classes.usageWidget}>
                                <img className={classes.img} src={item.img} alt="UPI usage image" />
                                <h3 className={classes.title}>{item?.title}</h3>
                                <div className={classes.desc}>{item?.desc}</div>
                            </div>
                        ))}
                    </div>

                    <div className="sectionHeading textAlign--center--mobile" style={{marginTop: 96}}>India runs on UPI</div>
                    <div className={`${classes.upiFeaturesContainerWrapper} marginTop--l4`}>
                        <div className={classes.upiFeaturesContainer}>
                            {upiFeatures.map((item, index) => (
                                <div key={index} className={classes.upiFeatureWidget}>
                                    <div className={classes.featureImg}
                                        style={{ backgroundImage: `url('${item.img}')` }}></div>
                                    <h3 className={`${classes.desc} marginTop--l3`}>{item.title}</h3>
                                </div>
                            ))}
                        </div>
                    </div>

                    <h3 className="sectionHeading textAlign--center--mobile" style={{marginTop: 92}}>Safer than Cash,<br/>better than a card</h3>
                    <div className={`${classes.comparisonContainer} marginTop--l4`}>
                        <div className={classes.comparisonRow}>
                            <div className={classes.col1}>Benefits</div>
                            <div className={classes.col2}>
                                <div className='flex--center--center flex__flow--column'>
                                    <img src="/assets/icons/cash.svg" style={{width: 20}}/>
                                    <div style={{marginTop: 4}}>Cash</div>
                                </div>
                            </div>
                            <div className={classes.col3}>
                                <div className='flex--center--center flex__flow--column'>
                                    <img src="/assets/icons/card.svg" style={{width: 20}}/>
                                    <div style={{marginTop: 4}}>Card</div>
                                </div></div>
                            <div className={classes.col4}>
                                <div className='flex--center--center flex__flow--column'>
                                    <img src="/assets/icons/logo.svg?v=2" style={{width: 20}}/>
                                    <div style={{marginTop: 4}}>NamasPay</div>
                                </div></div>
                        </div>
                        <div className={classes.comparisonRow}>
                            <h3 className={classes.col1}>Zero fee on every payment</h3>
                            <div className={classes.col2}>
                                <img src="/assets/icons/check.svg" style={{width: 20}}/>
                            </div>
                            <div className={classes.col3}>-</div>
                            <div className={classes.col4}>
                                <img src="/assets/icons/check-circle.svg" style={{width: 20}}/>
                            </div>
                        </div>
                        <div className={classes.comparisonRow}>
                            <h3 className={classes.col1}>Accepted everywhere in India</h3>
                            <div className={classes.col2}>
                                <img src="/assets/icons/check.svg" style={{width: 20}}/>
                            </div>
                            <div className={classes.col3}>-</div>
                            <div className={classes.col4}>
                                <img src="/assets/icons/check-circle.svg" style={{width: 20}}/>
                            </div>
                        </div>
                        <div className={classes.comparisonRow}>
                            <h3 className={classes.col1}>Secured against getting lost or stolen</h3>
                            <div className={classes.col2}>-</div>
                            <div className={classes.col3}>-</div>
                            <div className={classes.col4}>
                                <img src="/assets/icons/check-circle.svg" style={{width: 20}}/>
                            </div>
                        </div>
                        <div className={classes.comparisonRow}>
                            <h3 className={classes.col1}>Fast and convenient online payments</h3>
                            <div className={classes.col2}>-</div>
                            <div className={`${classes.col3} flex__flow--column`}>
                                <img src="/assets/icons/check.svg" style={{width: 20}}/>
                                <div style={{fontSize: 8}}>(Lengthy process)</div>
                            </div>
                            <div className={classes.col4}>
                                <img src="/assets/icons/check-circle.svg" style={{width: 20}}/>
                            </div>
                        </div>
                        <div className={classes.comparisonRow}>
                            <h3 className={classes.col1}>Easily track your spends</h3>
                            <div className={classes.col2}>-</div>
                            <div className={classes.col3}>
                                <img src="/assets/icons/check.svg" style={{width: 20}}/>
                            </div>
                            <div className={classes.col4}>
                                <img src="/assets/icons/check-circle.svg" style={{width: 20}}/>
                            </div>
                        </div>
                    </div>

                    <div className={classes.safeMoneyContainer}>
                        <div className={classes.details}>
                            <div className="sectionHeading textAlign--center--mobile">Safe money,<br />safer travels</div>

                            <div className="marginTop--l6">
                                {quickFeatures.map((item, index) => {
                                    return (
                                        <div key={index} className="flex--center--center"
                                            style={{
                                                background: '#F7F7F7',
                                                borderRadius: 25,
                                                marginTop: index > 0 ? 32 : 0,
                                                padding: 32
                                            }}>
                                            <img src={item.img} alt="App safety image" style={{ width: 64 }} />
                                            <h3 className={`${classes.desc} flex--1 marginLeft--l3`}>{item.title}</h3>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <img className={classes.img} src="/assets/images/safe_money.png?v=2" alt="Safe money creative" />
                    </div>
                </div>
            </div>

            <div className={classes.sec4Wrapper}>
                <div className={`${classes.sec4} pagePad__x`}>
                    <div className={`${classes.sectionHeading} sectionHeading textAlign--center--mobile`}>Powered by</div>
                    <div className={classes.imgsWrapper}>
                        <img src="/assets/icons/NPCI.svg" />
                        <img src="/assets/icons/UPI.svg" />
                        <img src="/assets/icons/trio-logo.svg" style={{marginTop: -5}} />
                    </div>
                </div>
            </div>

            <div className={classes.sec5Wrapper}>
                <div className={`${classes.sec5} pagePad__x`}>
                    <div className={classes.sec5Widget}>
                        <div className={classes.imgWrapper}>
                            <img src="/assets/images/across-country.webp" />
                        </div>

                        <div className={classes.details}>
                            <div className="sectionHeading textAlign--center--mobile">NamasPay your way across the country</div>

                            <div className={`${classes.acrossCountryListWrapper} marginTop--l4`}>
                                <div className={classes.acrossCountryList}>
                                    {arrayOf100Length?.map(() => (
                                        acrossCountryList?.map((item, index) => (
                                            <div key={index}>{item}</div>
                                        ))
                                    ))}
                                </div>
                            </div>

                            <div className={`${classes.desc} marginTop--l10`}>From arrival to departure, NamasPay's got you covered.</div>

                            <div className="marginTop--l4 display--flex flex__justifyContent--start">
                                <CTA type="secondary" text={isDesktop() ? "Download Now" : "Download Now"} onAction={onDownloadClick}></CTA>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.sec6Wrapper}>
                <div className={`${classes.sec6} pagePad__x`}>
                    <div className={classes.imgWrapper}>
                        <div className="sectionHeading textAlign--center--mobile">Getting started with NamasPay</div>
                        <img src="/assets/images/getting_started.png?v=2" className="width--full marginTop--l4" />
                    </div>
                    <div className={classes.details}>
                        {gettingStarted?.map((item, index) => (
                            <div key={index} className={classes.widget}>
                                <div className='flex--center--center'>
                                    <div className={`${classes.title} flex--1 marginRight--l3`}>{item?.title}</div>
                                    <img src={item?.img} style={{width: 36, height: 36}}/>
                                </div>
                                <div className={`${classes.desc} marginTop--l3`}>{item?.desc}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className={classes.faqWrapper}>
                <div className={`${classes.faqContainer} pagePad__x`}>
                    <SupportSection/>

                    <div id='faq' className="sectionHeading textAlign--center--mobile marginTop--l10">FAQs</div>

                    <div className="marginTop--l4">
                        {allFAQs.map((item, index) => (
                            <div key={index} className={`${classes.faqWidget} ${index > 0 ? 'marginTop--l3' : ''}`} onClick={() => setOpenedFaqIndex(index===openedFaqIndex ? -1 : index)}>
                                <div className="display--flex flex__justifyContent--between flex__alignItems--center cursor--pointer">
                                    <div className={classes.desc}>{item.title}</div>

                                    <img src="/assets/icons/downArrow.svg" alt="Down arrow icon" style={{ rotate: index===openedFaqIndex ? '180deg' : '0deg', marginLeft: 8 }} />
                                </div>

                                {index===openedFaqIndex && <div className={`${classes.desc} marginTop--l4 padTop--l4`}
                                                style={{ borderTop: '1px solid rgba(0,0,0,0.05)', color: '#7c7c7c' }} dangerouslySetInnerHTML={{__html: item.desc}}></div>}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Footer/>
        </main>
    );
};

export default HomeUI;
