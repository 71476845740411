export const upiUsages = [
    {
        title: 'Pay anywhere in India',
        desc: 'by scanning QR Codes at stores',
        img: '/assets/images/pay_anywhere.svg'
    },
    {
        title: 'Use any Mastercard or Visa card',
        desc: 'to add money into your wallet',
        img: '/assets/images/add_money_into_your_wallet.svg'
    },
    {
        title: 'Make online payments',
        desc: 'using your own UPI ID',
        img: '/assets/images/online_payments.svg'
    },
    {
        title: 'Track all your spends ',
        desc: 'while you travel and explore',
        img: '/assets/images/track_spends.svg'
    }
];

export const upiFeatures = [
    {
        title:
            'UPI (Unified Payments Interface) is the most widely used payments system in India',
        img: '/assets/images/upi-usage1.png'
    },
    {
        title:
            'Over 260 million Indians use UPI, making it India\'s preferred mode of payment',
        img: '/assets/images/upi-usage2.png'
    },
    {
        title:
            'Pay for cabs, hotels, food, shopping - UPI is accepted everywhere',
        img: '/assets/images/upi-usage3.png'
    }
];

export const quickFeatures = [
    {
        img: '/assets/icons/RBI.svg',
        title: 'NamasPay follows all RBI (Reserve Bank of India) guidelines'
    },
    {
        img: '/assets/icons/Lock.svg',
        title: 'All your transactions are protected with a device-lock'
    },
    {
        img: '/assets/icons/Scan.svg',
        title:
            'Avoid the risk of losing your cash and cards by using digital payments'
    }
];

export const gettingStarted = [
    {
        title: 'Download the NamasPay App ',
        desc: 'Sign up with your international or new Indian number.',
        img: '/assets/icons/Download.svg'
    },
    {
        title: 'Complete your physical verification',
        desc: 'Our agents will come to you to verify your passport & Visa.',
        img: '/assets/icons/Verification.svg'
    },
    {
        title: 'Add money and spend across India',
        desc: 'Transfer money in your currency using any Mastercard or Visa card, and start paying in Rupees!',
        img: '/assets/icons/Wallet.svg'
    }
];

export const acrossCountryList = ['Flights', 'Hotels', 'Cabs', 'Restaurants & food orders', 'Supermarkets', 'Online shopping'];
