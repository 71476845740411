// Libraries
import React from 'react';
import {NextSeo, WebPageJsonLd} from "next-seo";
import Head from "next/head";

// Components
import HomeUI from '../components/HomeUI';

// Constants
import {META_INFO} from "../constants/metaConstants";


export default function Home() {
    return (
        <>
            <Head>
                <meta content={META_INFO.DEFAULT_TITLE} name="twitter:title"/>
                <meta content={META_INFO.DEFAULT_URL} name="twitter:url"/>
            </Head>

            <NextSeo
                title={META_INFO.DEFAULT_TITLE}
                canonical={META_INFO.DEFAULT_URL}
                openGraph={{
                    title: META_INFO.DEFAULT_TITLE,
                    url: META_INFO.DEFAULT_URL
                }}
            />

            <WebPageJsonLd
                id={META_INFO.DEFAULT_URL}
                description={META_INFO.LONG_DESC}
            />

            <HomeUI />
        </>
    );
}
