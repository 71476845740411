// Modules
import React, {useEffect, useState} from 'react';
import Link from "next/link";

// Styles
import classes from './HomeUI.module.scss';

// Components
import CTA from '../elements/CTA';

// Utils
import {onDownloadClick} from "../utils/helperFunctions";


const Header = () => {
    const [isBrowser, setIsBrowser] = useState(false);
    useEffect(() => {
        if (process.browser)
            setIsBrowser(true);
    }, [])

    const isDesktop = () => {
        if (!isBrowser)
            return true;

        const isMobile = ('ontouchstart' in window); // likely mobile
        return !isMobile;
    }

    return (
        <div className={classes.headerWrapper}>
            <div className={`${classes.header} pagePad__x`}>
                <Link href={'/'}>
                    <img className='cursor--pointer' src="/assets/icons/logoFullBlack.svg" alt="NamasPay logo" style={{ height: 56, width: 296 }} />
                </Link>


                <CTA className={classes.downloadCTA} type="primary" text={isDesktop() ? "Download Now" : "Download Now"} onAction={onDownloadClick}></CTA>
            </div>
        </div>
    );
};

export default Header;
